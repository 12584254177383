import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news.vue')
  },
  {
    path: '/newsdetail',
    name: 'newsdetail',
    component: () => import('@/views/newsdetail.vue')
  },
  {
    path: '/social',
    name: 'social',
    component: () => import('@/views/social.vue')
  },
  {
    path: '/job',
    name: 'job',
    component: () => import('@/views/job.vue')
  },
  {
    path: '/jobdetail',
    name: 'jobdetail',
    component: () => import('@/views/jobdetail.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
